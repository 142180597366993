<template>
  <div class="d-flex flex-column" style="min-height: 100vh;">
    <LogoutTimer />
    <TheHeader
      :tenant="tenant"
      :username="username"
      :signedIn="signedIn"
      :userFirstName="userFirstName"
    />

    <Alert v-if="alert && alert.layer === 'public'" :alert="alert" />
    <div id="content" class="flex-grow-1">
      <router-view />
    </div>
    <TheFooter :tenant="tenant" />
  </div>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import Alert from "@/components/alert/Alert.vue";
import LogoutTimer from "@/components/layout/LogoutTimer.vue";

export default {
  name: "PublicApp",
  tile: "Home",
  components: {
    TheHeader,
    TheFooter,
    Alert,
    LogoutTimer
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    userFirstName() {
      return this.$store.getters["auth/userFirstName"];
    },
    signedIn() {
      return this.$store.getters["auth/signedIn"];
    },
    alert: {
      cache: false,
      get() {
        return this.$store.getters["alert/errorAlert"];
      }
    }
  }
};
</script>
