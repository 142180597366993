<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light p-2"
    role="navigation"
    aria-label="Header"
  >
    <a
      class="navbar-brand m-0 px-2 py-0"
      href="javascript:void(0);"
      @click="$router.push('/').catch(() => {})"
    >
      <img
        src="@/assets/images/logos/DNR-IL-FinalColors.png"
        height="60"
        alt="Illinois Department of Natural Resources"
        v-if="tenant.websiteLogoUrl === 'n/a'"
      />
      <img
        :src="tenant.websiteLogoUrl"
        height="40"
        :alt="tenant.name + ' Logo'"
        v-if="tenant.websiteLogoUrl !== 'n/a'"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="d-none d-lg-block">
      <p class="department m-0">{{ tenant.departmentName }}</p>
      <h1 class="m-0">{{ tenant.siteName }}</h1>
      <p class="tagline m-0">"{{ tenant.tagline }}"</p>
    </div>
    <div class="collapse navbar-collapse mr-3 py-2" id="navbarSupportedContent">
      <form class="form-inline ml-2 ml-lg-auto">
        <button
          v-if="!signedIn"
          class="btn btn-primary p-2 profile-button"
          type="button"
          @click="goToLogin"
        >
          LOGIN / CREATE ACCOUNT
        </button>
        <div v-if="signedIn" class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle p-2 profile-button"
            type="button"
            id="accountMenu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span v-if="userFirstName == 'LOADING...'">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Loading...</span>
            </span>
            <span v-else>
              {{
                userFirstName
                  ? userFirstName.toUpperCase()
                  : username
                  ? username.toUpperCase()
                  : "USER"
              }}
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="accountMenu">
            <button class="dropdown-item" type="button" @click="goToProfile">
              PROFILE
            </button>
            <button
              class="dropdown-item"
              type="button"
              @click="goToReservations"
            >
              RESERVATIONS
            </button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" type="button" @click="logOut">
              SIGN OUT
            </button>
          </div>
        </div>
        <button
          class="btn btn-primary d-md-block d-lg-none p-2 mx-2"
          type="button"
          @click="viewCart"
          v-observe-visibility="mobileCartVisibilityChange"
        >
          <CartTimer v-if="!showFullScreenTimer" />
        </button>
      </form>
    </div>
    <div class="cart my-n2 mr-n2 px-2 pt-4 d-none d-lg-block" v-if="!hideCart">
      <button
        class="btn align-middle cart-button"
        type="button"
        @click="viewCart"
      >
        <CartTimer v-if="showFullScreenTimer" />
      </button>
    </div>
  </nav>
</template>

<script>
import CartTimer from "@/components/layout/CartTimer.vue";

export default {
  name: "TheHeader",
  props: {
    userFirstName: String,
    username: String,
    signedIn: Boolean
  },
  data() {
    return {
      showFullScreenTimer: false
    };
  },
  components: { CartTimer },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    externalUserId() {
      return this.$store.getters["auth/externalUserId"];
    },
    hideCart() {
      return (
        this.$route.name == "CreateAccount" || this.$route.name == "ForgotLogin"
      );
    }
  },
  methods: {
    setFocus() {
      document.getElementById("app").focus();
    },
    goToLogin() {
      this.$router.push("/login").catch(() => {});
    },
    goToProfile() {
      this.$router
        .push(`/customer-profile/${this.externalUserId}`)
        .catch(() => {});
    },
    goToReservations() {
      if (this.$route.hash === "#reservations") {
        this.$router
          .push(`/customer-profile/${this.externalUserId}#reservations?r=t`)
          .catch(() => {});
      } else {
        this.$router
          .push(`/customer-profile/${this.externalUserId}#reservations`)
          .catch(() => {});
      }
    },
    mobileCartVisibilityChange(isVisible) {
      this.showFullScreenTimer = !isVisible;
    },
    async logOut() {
      try {
        this.$store.dispatch("auth/logOut");
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    viewCart() {
      this.$router.push("/shopping-cart").catch(() => {});
    }
  }
};
</script>

<style scoped>
.department {
  font-weight: bold;
}
.tagline {
  font-style: italic;
}
.navbar {
  background-color: #e6e6e6 !important;
}
.cart {
  height: 83px;
  background-color: #ffffff;
  box-shadow: inset 6px 0 10px -8px #000000;
}
.profile-button {
  min-width: 100px;
}
.cart-button {
  font-size: large;
  border: none;
}
h1 {
  font-size: 1.3125rem;
}
</style>
